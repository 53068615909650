<template>
  <div>
    <vs-card>
      <h4 class="mb-2 ml-5 mt-2">Admin Detail</h4>

      <div class="w-full" align="right">
        <vs-button size="normal" @click="saveData" class="m-2">Save</vs-button>
        <!-- <vs-button size="normal" @click="popUpDelete = true" class="m-2" color="danger" >Delete</vs-button> -->
        <vs-button @click="resetPassword = true" icon-pack="feather" class="m-2"
          >Change Password</vs-button
        >
        <vs-popup :active.sync="popUpDelete" title="Delete Confirmation">
          <p align="center">Are you sure you want to delete this sub clinic?</p>
          <br />
          <vs-row
            vs-align="flex-start"
            vs-type="flex"
            vs-justify="left"
            vs-w="12"
            vs-mt="6"
          >
            <vs-col vs-type="flex" vs-align="left" vs-w="6">
              <div class="w-full m-5" align="right">
                <vs-button
                  size="lg"
                  color="primary"
                  @click="deleteSubClinicHandler"
                  >Yes, delete</vs-button
                >
              </div>
            </vs-col>
            <vs-col vs-type="flex" vs-align="left" vs-w="6">
              <div class="w-full m-5">
                <vs-button size="lg" color="danger" @click="popUpDelete = false"
                  >Cancel</vs-button
                >
              </div>
            </vs-col>
          </vs-row>
        </vs-popup>
      </div>
      <form autocomplete="off">
        <vs-row
          vs-align="flex-start"
          vs-type="flex"
          vs-justify="left"
          vs-w="12"
        >
          <vs-col vs-type="flex" vs-w="6">
            <div class="w-full m-5">
              <label for>Disable User</label>
              <vs-switch
                color="danger"
                id="userStatusLoader"
                @click="changeStatus(userStatus)"
                class="m-2"
                v-model="userStatus"
              />
              <div
                v-if="statusChangedClick"
                style="width: 20px;height: auto; display: flex;"
              >
                <span style="margin-right: 5px;">Processing</span>

                <img
                  src="../../../../public/loader.gif"
                  style="width: 100%; height: 100%;"
                />
              </div>
            </div>
          </vs-col>
          <vs-col vs-type="flex" vs-justify="left" vs-align="left" vs-w="6">
            <div class="w-full m-5">
              <vs-input
                :success="
                  !errors.first('basic.firstName') && clinicData.firstName != ''
                "
                val-icon-success="done"
                val-icon-danger="error"
                :danger="errors.first('basic.firstName') ? true : false"
                v-validate="'required|max:150'"
                name="firstName"
                data-vv-as="first name"
                label="First Name"
                class="w-full"
                data-vv-scope="basic"
                :danger-text="errors.first('basic.firstName')"
                v-model="clinicData.firstName"
              />
            </div>
          </vs-col>
          <vs-col vs-type="flex" vs-justify="left" vs-align="left" vs-w="6">
            <div class="w-full m-5">
              <vs-input
                :success="
                  !errors.first('basic.lastName') && clinicData.lastName != ''
                "
                val-icon-success="done"
                val-icon-danger="error"
                :danger="errors.first('basic.lastName') ? true : false"
                v-validate="'required|max:150'"
                name="lastName"
                data-vv-as="last name"
                label="Last Name"
                class="w-full"
                data-vv-scope="basic"
                :danger-text="errors.first('basic.lastName')"
                v-model="clinicData.lastName"
              />
            </div>
          </vs-col>
          <!-- <vs-col vs-type="flex" vs-justify="left" vs-align="left" vs-w="6">
            <div class="w-full m-5">
              <vs-input
                :success="!errors.first('basic.contactNumber') && clinicData.contactNumber!=''"
                val-icon-success="done"
                val-icon-danger="error"
                :danger="(errors.first('basic.contactNumber') ? true : false)"
                v-validate="'required|max:150'"
                name="contactNumber"
                data-vv-as="contact number"
                label="Contact Number"
                class="w-full"
                data-vv-scope="basic"
                :danger-text="errors.first('basic.contactNumber')"
                v-model="clinicData.contactNumber"
              />
            </div>
          </vs-col>-->
          <vs-col
            vs-w="6"
            id="profileImageUpload"
            class="vs-con-loading__container"
          >
            <label class="ml-5">Upload Profile Picture</label>
            <div>
              <div
                class="mb-4"
                v-if="clinicData.profileImage"
                style="margin:auto;width:35%;"
              >
                <vs-avatar
                  size="130px"
                  align="center"
                  :src="clinicData.profileImage"
                />
              </div>

              <div style="text-align:center;position:relative;">
                <label
                  class="image-upload"
                  style="border:1px solid #cccccc; display:inline-block;width:100%; border-radius: 5px; padding:10px;"
                >
                  Upload a profile photo
                  <input
                    type="file"
                    accept="image/*"
                    @change="uploadImage($event, 'profileImageUpload')"
                    id="file-input"
                    hidden
                  />
                </label>
              </div>
            </div>
          </vs-col>
        </vs-row>
      </form>
    </vs-card>
    <div id="resetPasswordContainer">
      <vs-card v-if="resetPassword == true">
        <h4 class="mb-2 ml-5 mt-2">Reset Password</h4>
        <form autocomplete="off">
          <vs-row
            vs-align="flex-start"
            vs-type="flex"
            vs-justify="left"
            vs-w="12"
          >
            <vs-col vs-type="flex" vs-justify="left" vs-align="left" vs-w="6">
              <div class="w-full m-5">
                <vs-input
                  :success="
                    !errors.first('resetPassword.newPassword') && password != ''
                  "
                  val-icon-success="done"
                  val-icon-danger="error"
                  :danger="
                    errors.first('resetPassword.newPassword') ? true : false
                  "
                  name="newPassword"
                  :danger-text="errors.first('resetPassword.newPassword')"
                  label="New Password"
                  class="w-full"
                  v-validate="{
                    required: true,
                    min: 6,
                    regex: /^.*(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(^[a-zA-Z0-9_.*\W]+$)/
                  }"
                  data-vv-as="new password"
                  data-vv-scope="resetPassword"
                  ref="password"
                  icon-no-border
                  icon-pack="feather"
                  v-model="password"
                  :icon="passwordIcon"
                  :type="passwordType"
                  @click.native="showHidePassword($event)"
                />
              </div>
            </vs-col>

            <vs-col vs-type="flex" vs-justify="left" vs-align="left" vs-w="6">
              <div class="w-full m-5">
                <vs-input
                  label="Confirm Password"
                  :success="
                    !errors.first('resetPassword.confirmPassword') &&
                      confirmPassword != ''
                  "
                  val-icon-success="done"
                  val-icon-danger="error"
                  :danger="
                    errors.first('resetPassword.confirmPassword') ? true : false
                  "
                  name="confirmPassword"
                  :danger-text="errors.first('resetPassword.confirmPassword')"
                  v-validate="'required|confirmed:password'"
                  data-vv-as="confirm password"
                  data-vv-scope="resetPassword"
                  class="w-full"
                  icon-no-border
                  icon-pack="feather"
                  v-model="confirmPassword"
                  :icon="confirmPasswordIcon"
                  :type="confirmPasswordType"
                  @click.native="showHideConfirmPassword($event)"
                />
              </div>
            </vs-col>
            <vs-col vs-type="flex" vs-align="left" vs-w="6">
              <div class="w-full m-5">
                <vs-button
                  size="lg"
                  @click="changePassword"
                  icon-pack="feather"
                  class="mr-2"
                  >Reset Password</vs-button
                >
              </div>
            </vs-col>
            <vs-col vs-type="flex" vs-align="left" vs-w="6">
              <div class="w-full m-5" align="right">
                <vs-button
                  @click="resetPassword = false"
                  color="danger"
                  icon-pack="feather"
                  class="mr-2"
                  >Cancel</vs-button
                >
              </div>
            </vs-col>
          </vs-row>
        </form>
      </vs-card>
    </div>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import { Validator } from "vee-validate";
import axios from "@/axios";

const dict = {
  custom: {
    newPassword: {
      required: "Please enter your password",
      min: "Password must be at least 6 characters",
      regex: "Must have at least one number and  one uppercase letter"
    },
    confirmPassword: {
      required: "Please enter your confirm password",
      confirmed: "The password confirmation does not match"
    }
  }
};
Validator.localize("en", dict);

export default {
  data: () => ({
    userStatus: false,
    clinicId: "",
    password: "",
    confirmPassword: "",
    clinicData: {
      firstName: "",
      lastName: "",
      // contactNumber: "",
      profileImage: "",
      status: ""
    },
    clinicTempData: {
      firstName: "",
      lastName: "",
      // contactNumber: "",
      profileImage: "",
      status: ""
    },
    resetPassword: false,
    statusChangedClick: false,
    popUpDelete: false,
    passwordType: "password",
    passwordIcon: "icon icon-eye-off",
    confirmPasswordType: "password",
    confirmPasswordIcon: "icon icon-eye-off"
  }),
  methods: {
    ...mapActions("clinic", [
      "updateClinicAdmin",
      "fetchClinicAdminDetail",
      "deleteSubClinic",
      "resetClinicPassword"
    ]),
    showHidePassword(event) {
      if (
        event.target.className ===
          "vs-icon notranslate icon-scale icon-inputx notranslate vs-input--icon feather icon icon-eye-off null icon-no-border" ||
        event.target.className ===
          "vs-icon notranslate icon-scale icon-inputx notranslate vs-input--icon feather icon icon-eye null icon-no-border"
      ) {
        this.passwordType === "password"
          ? (this.passwordType = "text")
          : (this.passwordType = "password");
        this.passwordIcon === "icon icon-eye-off"
          ? (this.passwordIcon = "icon icon-eye")
          : (this.passwordIcon = "icon icon-eye-off");
      }
      return true;
    },
    async changeStatus(status) {
      this.statusChangedClick = true;
      let us = "Active";
      if (!status) us = "Disabled";

      this.clinicTempData.status = us;
      await this.updateClinicAdmin({
        clinicData: this.clinicTempData,
        clinicId: this.clinicId
      })
        .then(res => {
          // this.doctorData.emailVerifiedAt = res.data.data.emailVerifiedAt;
          this.$vs.notify({
            title: "Success",
            text: "Status updated successfully.",
            color: "success"
          });
          this.statusChangedClick = false;
        })
        .catch(err => {
          this.$vs.notify({
            title: "Failed",
            text: "Failed to update status. Try again!",
            color: "danger"
          });
          this.statusChangedClick = false;
        });
    },
    showHideConfirmPassword(event) {
      if (
        event.target.className ===
          "vs-icon notranslate icon-scale icon-inputx notranslate vs-input--icon feather icon icon-eye-off null icon-no-border" ||
        event.target.className ===
          "vs-icon notranslate icon-scale icon-inputx notranslate vs-input--icon feather icon icon-eye null icon-no-border"
      ) {
        this.confirmPasswordType === "password"
          ? (this.confirmPasswordType = "text")
          : (this.confirmPasswordType = "password");
        this.confirmPasswordIcon === "icon icon-eye-off"
          ? (this.confirmPasswordIcon = "icon icon-eye")
          : (this.confirmPasswordIcon = "icon icon-eye-off");
      }
      return true;
    },
    async saveData() {
      let self = this;
      let isValid = await this.$validator.validateAll("basic");
      if (isValid) {
        if (this.userStatus == true) this.clinicData.status = "Disabled";
        else this.clinicData.status = "Active";

        await this.updateClinicAdmin({
          clinicData: this.clinicData,
          clinicId: this.clinicId
        })
          .then(res => {
            this.getClinicAdminDetail(this.clinicId);
            self.$vs.notify({
              title: "Sub Clinic Admin Updated",
              text: "Sub Clinic admin updated successfully.",
              color: "success"
            });
          })
          .catch(err => {
            self.$vs.notify({
              title: "Failed",
              text: "Failed to update clinic admin. Try again!",
              color: "danger"
            });
            isValid = false;
            if (err.status === 422) {
              self.mapServerErrors(self, err, "basic");
            }
            if (err.status === 417) {
              self.errors.add({
                field: "email",
                msg: err.data.message,
                scope: "basic"
              });
            }
          });
      }
    },
    mapServerErrors(self, err, form = "") {
      let data = err.data.data.details;
      data.map(function(value, key) {
        self.errors.add({
          field: value.context.key,
          msg: value.message.replace(/"/g, ""),
          scope: form
        });
      });
    },
    async getClinicAdminDetail(id) {
      let self = this;
      await this.fetchClinicAdminDetail(id).then(res => {
        const clinic = res.data.data;
        self.clinicId = clinic._id;
        self.clinicData.firstName = clinic.firstName;
        self.clinicData.lastName = clinic.lastName;
        // self.clinicData.contactNumber = clinic.contactNumber;
        self.clinicData.profileImage = clinic.profileImage;

        if (clinic.status == "Active") this.userStatus = false;
        else this.userStatus = true;

        self.clinicTempData.firstName = clinic.firstName;
        self.clinicTempData.lastName = clinic.lastName;
        // self.clinicTempData.contactNumber = clinic.contactNumber;
        self.clinicTempData.profileImage = clinic.profileImage;
      });
    },
    async changePassword() {
      let self = this;
      const isValid = await this.$validator.validateAll("resetPassword");
      if (isValid) {
        await this.resetClinicPassword({
          id: this.clinicId,
          data: {
            password: this.password,
            confirmPassword: this.confirmPassword
          }
        }).then(res => {
          self.password = "";
          self.confirmPassword = "";
          self.$validator.reset();
          self.$vs.notify({
            title: "Sub clinic admin password reset",
            text: "Sub clinic admin's password reset Successfully.",
            color: "success"
          });
        });
      }
    },
    uploadImage(event, key = "profileImage") {
      this.$vs.loading({
        text: "Uploading...",
        color: "#3dc9b3",
        background: "white",
        container: "#" + key,
        scale: 0.6
      });
      let self = this;
      let data = new FormData();
      data.append("profileImage", event.target.files[0]);
      let config = {
        header: {
          "Content-Type": "image/png"
        }
      };
      axios
        .post("/api/v1/doctors/upload?key=" + key, data, config)
        .then(res => {
          console.log(res.data.Location);
          self.clinicData.profileImage = res.data.Location;

          this.$vs.loading.close("#" + key + " > .con-vs-loading");
        })
        .catch(err => {
          this.$vs.loading.close("#" + key + " > .con-vs-loading");
          if (err && err.status === 415) {
            this.$vs.notify({
              title: "Upload Failed",
              text: "File Format Not Supported",
              color: "danger"
            });
          } else {
            this.$vs.notify({
              title: "Upload Failed",
              text: "Please try again later.",
              color: "danger"
            });
          }
        });
    },
    deleteSubClinicHandler() {
      let user = this.$store.state.AppActiveUser.userRole;
      this.popUpDelete = false;
      let id = this.clinicId;
      this.deleteSubClinic(id).then(res => {
        this.$vs.notify({
          subject: "Deleted Sub Clinic",
          text: "Sub Clinic deleted Successfully.",
          color: "success"
        });
        if (user === "superAdmin") {
          this.$router.push("/super-admin/sub-clinic");
        } else if (user === "subClinic") {
          this.$router.push("/clinics/sub-clinic");
        } else {
          this.$router.push("/" + user + "/sub-clinic");
        }
      });
    }
  },
  async created() {
    this.clinicId = this.$route.params.clinicId;
    await this.getClinicAdminDetail(this.clinicId);
  }
};
</script>
